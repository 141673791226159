import React, { useEffect, useRef } from "react";
import axios from 'axios';
import stopSpeakingICON from "../../assests/mute_image.png";
import createAnimation from "../converter";
import ReactAudioPlayer from "react-audio-player";
import { Html } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import OpenAI from "openai";
import Lottie from "lottie-react";
import listening from '../../assests/icon_animations/listening.json'
import speaking from '../../assests/icon_animations/speaking.json'
import loading from '../../assests/icon_animations/loading-dots.json'
import demodata from './data/blend250_out.json';
import audioFile from './data/modi1.wav';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { v4 as uuidv4 } from 'uuid';
import { json } from "react-router-dom";
import useState from 'react-usestateref'
const _ = require('lodash');
//require('dotenv').config();
const genAI = new GoogleGenerativeAI('AIzaSyBFOkj3Cla3JGGYS1xDTEF6Uol3Mv-Jugc');
const model = genAI.getGenerativeModel({ model: "gemini-pro" });
const ACCESS_TOKEN_FOR_MENTAL_HEALTH_API = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZW1vX2RlcGxveW1lbnQiOnRydWUsImlhdCI6MTcyMDQ0MjUyOSwiZXhwIjoxNzIyODYxNzI5fQ.2Hl1VIDakxitXnI5pDLgEHaoouC3HzwWkk88l4a1XeU"
// const ACCESS_TOKEN_FOR_MENTAL_HEALTH_API = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZW1vX2RlcGxveW1lbnQiOnRydWUsImlhdCI6MTcxMTEwODc0MSwiZXhwIjoxNzEzNTI3OTQxfQ.ZHw1r_EAIReWg6t2VUzD8_9nI9q32lpD7jABaetZkAw"

const openai = new OpenAI({apiKey: process.env.REACT_APP_OPENAI_KEY, dangerouslyAllowBrowser: true});


const ChatWithMicComponent = ({ userEmail, accessToken, clips, setClips, morphTargets, morphTargetsName, ChatTextBoxtoggle, setChatTextBoxtoggle, setRef, recognition, speechSynthesis, displayOption, setDisplayOption }) => {

    console.log("userEmail==================", userEmail)
    const initial_message = {
        text: "Welcome to a non-judgmental space! You're not alone in your journey. We are here to support you through Mental Health. Please share more about yourself for personalized help.",
        sender: "GenAI"
    };

    const [hasHistoryApplied, setHasHistoryApplied] = useState(false);
    const [audioSource, setAudioSource] = useState(null);
    const [messages, setMessages, messagesref] = useState([initial_message]);
    const [currentOptions, setcurrentOptions] = useState([]);
    const [uuid, setuuid] = useState("");
    const [ChoreographID, setChoreographID,ChoreographIDref ] = useState("6614e7f13e29ac0fe3486441");
    const [selectedOption, setSelectedOption] = useState({});
    const [selectedOptions, setSelectedOptions] = useState({});

    const [isListening, setIsListening] = useState(false);
    const [reqSent, setReqSent] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [IsconversationEnd, setIsconversationEnd] = useState(false);
    const audioPlayer = useRef();
    const micUsed = useRef(false);
    const messagesEndRef = useRef(null);
    const recognitionAborted = useRef(false);
    const [showOptions, setShowOptions] = useState(false);
    const [textInput, setTextInput] = useState("");

    const count = useRef(0);

    // useEffect(() => {
    //     const handleUserInteraction = () => {
    //         const initial_message = {
    //             text: "Welcome to a non-judgmental space! You're not alone in your journey. We are here to support you through Mental Health. Please share more about yourself for personalized help.",
    //             sender: "GenAI",
    //             options: undefined
    //         };
 
    //         if (count.current === 0) {
    //             const newUuid = uuidv4();
    //             console.log("newUuid:", newUuid);
    //             setuuid(newUuid);
    //             setTimeout(() => {
    //                 speakStartingText(initial_message);
    //             }, 1000);
    //             count.current += 1;
    //         }
           
    //         // Remove event listener after first interaction
    //         window.removeEventListener('click', handleUserInteraction);
    //         window.removeEventListener('keydown', handleUserInteraction);
    //     };
 
    //     // Add event listeners to capture first user interaction
    //     window.addEventListener('click', handleUserInteraction);
    //     window.addEventListener('keydown', handleUserInteraction);
 
    //     return () => {
    //         window.removeEventListener('click', handleUserInteraction);
    //         window.removeEventListener('keydown', handleUserInteraction);
    //     };
    // }, []);

    useEffect(() => {
        const handleUserInteraction = () => {
            if (count.current === 0) {
                const newUuid = uuidv4();
                console.log("newUuid:", newUuid);
                setuuid(newUuid);
                count.current += 1;
            }
        };
        handleUserInteraction();
    }, []);

    useEffect(() => {
        // This will trigger every time `selectedOption` is updated and can be used for any follow-up actions
        if (Object.keys(selectedOption).length > 0) {
            console.log("Selected option updated:", selectedOption);
        }
    }, [selectedOption]);


    async function speakStartingText(initial_message) {
        try {
            const startTime = performance.now();
            const formData = new FormData();
            console.log("==========Starting Text", initial_message)
            formData.append('text', initial_message.text);
            const response = await axios.post(process.env.REACT_APP_AVATAR_BACKEND_URI + `/synthesize?blend=true`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            let { blend_data, synthesized_audio } = response.data;
            console.log("Execution time(blandshapes):", performance.now() - startTime, "milliseconds");

            const newClips = [];
            //Below blend_data wll be null only in chat only option case
            if (blend_data !== null) {
                for (let i = 0; i < Object.keys(morphTargets).length; i++) {
                    newClips.push(createAnimation(blend_data, morphTargets[i], morphTargetsName[i]));
                }
            }
            synthesized_audio = process.env.REACT_APP_AVATAR_BACKEND_URI + '/' + synthesized_audio;

            setAudioSource(synthesized_audio);
            setRef();
            setClips(newClips);
            const newBotMessage = { text: initial_message.text, sender: "GenAI"};
            setMessages((pre) => [...pre, newBotMessage]);
            console.log(messages);

        } catch (err) {
            console.error(err);
            console.log(initial_message.text);
            speak(initial_message.text);
            const newBotMessage = { text: initial_message.text, sender: "GenAI"};
            setMessages((pre) => [...pre, newBotMessage]);
        }

        
    }



    const StarttheConversation = async (choreographid) => {

        try {

            var baseURL = `https://sync.runtime.cognitivegarage.ai/v3`;
            const data = {
                channel: "web",
                deployment_id: choreographid,
                uuid: uuid,
                type: "INIT_SESSION",
                value: ""
            };
            const config = {
                headers: {
                    'Authorization': `Bearer ${ACCESS_TOKEN_FOR_MENTAL_HEALTH_API}`,
                    'Content-Type': 'application/json',
                    'x-request-id': `${uuid}-${choreographid}`
                },
            };
            const startTime = performance.now();
            const response = await axios.post(baseURL, data, config);
            console.log("response:===", response.data.data);
            console.log("Execution time:", performance.now() - startTime, "milliseconds");

            if (choreographid === "661e25b7a365a79fba0b20dc" && response.data.data.text === 'Where are you currently residing?') {
                SkipTheDuplicateConversation(response.data.data);
            } else {
                makeSpeech(response.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const SkipTheDuplicateConversation = async (responseData) => {
        console.log("SkipTheDuplicateConversation================",ChoreographIDref.current)
        try {
            var baseURL = `https://sync.runtime.cognitivegarage.ai/v3`;
            const data = {
                channel: "web",
                deployment_id: ChoreographIDref.current,
                uuid: uuid,
                type: "NBQ",
                value: responseData.options[0].id
            };
            const config = {
                headers: {
                    'Authorization': `Bearer ${ACCESS_TOKEN_FOR_MENTAL_HEALTH_API}`,
                    'Content-Type': 'application/json',
                    'x-request-id': `${uuid}-${ChoreographIDref.current}`
                },
            };
            const startTime = performance.now();
            const response = await axios.post(baseURL, data, config);
            console.log("response:===", response.data.data);
            console.log("Execution time:", performance.now() - startTime, "milliseconds");
            if (response.data.data.text === 'Have you been stressed about something recently?') {
                SkipTheDuplicateConversation(response.data.data);
            } else {
                makeSpeech(response.data);
            }

        } catch (err) {
            console.log(err);
        }
    }

    //console.log(recognitionAborted.current);
    // console.log(messages);
    //console.log("isListening....",isListening);
    // console.log(ChatTextBoxtoggle);
    // console.log(reqSent);
    // console.log(playing);
    // console.log(hasHistoryApplied);
    // console.log(audioSource);



    useEffect(() => {

        if (!hasHistoryApplied) {
            const urlSearchParams = new URLSearchParams(window.location.search);

            const ticketId = urlSearchParams.get('ticketid');
            console.log("ticketId================:", ticketId)
            if (ticketId) {
                fetch(process.env.REACT_APP_DISPATCHER_BASE_URL + '/Default/GetHistory', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ticketid: ticketId }),
                }).then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                }).then(data => {
                    if (data.length && data.length > 0) {
                        data.map((x) => {
                            const newBotMessage = { text: x.message, sender: x.from, timestamp: formatTime() };
                            setMessages((pre) => [...pre, newBotMessage]);
                        });
                        setHasHistoryApplied(true);
                    }
                }).catch((exception) => {
                    console.log("Failed to load history");
                })
            }
        }
        
    }, []);


    // Utility function to format the current time
    const formatTime = () => {
        return new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    // const findAndSelectMatchingOption = (text) => {
    //     const lowerCaseText = text.toLowerCase();
    //     const foundIndex = messages.findIndex(option => option.id.toLowerCase() === lowerCaseText);
    //     console.log("foundIndex========>>>>>>>>>>>>", foundIndex)
    //     if (foundIndex !== -1) {
    //         const foundOption = currentOptions[foundIndex];
    //         console.log("foundOption========>>>>>>>>>>>>", foundOption)
    //         //calling the handle option change fucntion just to update  the selected value in state by voice and text
    //         handleOptionChange(foundIndex, foundOption.id, foundOption.text, true); // Pass index along with id and text
    //     }
    // };

    // const findAndSelectMatchingOption = (text) => {
    //     const lowerCaseText = text.toLowerCase();
    //     // Assuming messages is an array of message objects each containing an array of options.
    //     for (const message of messages) {
    //         if (message.options && message.options.length > 0) {
    //             const foundOption = message.options.find(option => option.id && option.id.toLowerCase() === lowerCaseText);
    //             if (foundOption) {
    //                 console.log("foundOption========>>>>>>>>>>>>", foundOption);
    //                 // Assuming handleOptionChange function exists and currentOptions array is correctly indexed to messages options.
    //                 const foundIndex = message.options.indexOf(foundOption);
    //                 console.log("Found Option Index", foundIndex)
    //                 if (foundIndex !== -1) {
    //                     // Pass index along with id and text
    //                     handleOptionChange(foundIndex, foundOption.id, foundOption.text, true);
    //                 }
    //             }
    //         }
    //     }
    // };

    const findAndSelectMatchingOption = (text) => {
        const lowerCaseText = text.toLowerCase();
        let found = false;

        // Assuming `messages` contains all chat messages, each with possible `options`
        messages.forEach((message, index) => {
            if (message.options) {
                const foundOption = message.options.find(option => option.id.toLowerCase() === lowerCaseText);
                if (foundOption) {
                    console.log("Automatically selecting option:", foundOption);
                    handleOptionChange(index, foundOption.id, foundOption.text, true);
                    found = true;
                }
            }
        });

        if (!found) console.log("No matching option found for:", text);
    };

    const saveHistory = async (text, type) => {
        console.log("saveHistory====================", text, type);
        try {

            let JsonBody
            if (type === "user") {
                JsonBody = JSON.stringify({
                    "Channel": "mentalhealth",
                    "From": userEmail,
                    "Message": text,
                    "Subject": "Sample Subject",
                    "TicketId": "",
                    "To": "GenAI",
                    "UserId": "",
                    "FileURL": null
                })
            } else {
                JsonBody = JSON.stringify({
                    "Channel": "mentalhealth",
                    "From":"GenAI",
                    "Message": text,
                    "Subject": "Sample Subject",
                    "TicketId": "",
                    "To": userEmail,
                    "UserId": "",
                    "FileURL": null
                })
            }
            const response = await fetch('https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/helper_routers/create_logs', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body:JsonBody
            });

            const data = await response.json();
            console.log("data============", data)
        } catch (error) {
            console.error('Error calling API:', error);
        }
    };

    const sendMessage = async (text, fromMic, optiontext, slectedFromOptions) => {
        saveHistory(text, "user");

        if (fromMic) micUsed.current = true;
        else {
            setIsListening(false);
            micUsed.current = false;
        }
        console.log(micUsed.current);

        if (!text.trim()) return;
        let TextForMentalHealthAPI = "";
        //if the length of message is one then it means conversation just started 
        if (messages.length > 1 && !IsconversationEnd) {
            //if the user select the option then no need to pass text in API
            if (!slectedFromOptions) {
                console.log("currentOptions:", currentOptions);
                let optionsSting = "";
                for (let i = 0; i < currentOptions.length; i++) {
                    let keys = currentOptions[i]["id"]
                    let value = currentOptions[i]["text"]
                    optionsSting += keys + "\n"
                }
                const startTime = performance.now();
                const prompt = `
                #objective : my answer is ${text} and i have these options \n ${optionsSting}. which option is suitable with my answer return only that option
                #Rules : for answers like maybe should take as yes and select No only if answer include "No" and related word.`;
                console.log("prompt==========,", prompt)
                const completion = await openai.chat.completions.create({
                    messages: [{"role": "system", "content": "You are a helpful assistant who can select the best option for given text"},
                               {"role": "user", "content": prompt},
                            ],
                    model: "gpt-4o",
                  });
                
                console.log(completion.choices[0]);
                //const result = await model.generateContent(prompt);
                let result = completion.choices[0].message.content;
                console.log("result==>", result);
                //const response = result.response;
                result = result.replace(/(\r\n|\n|\r)/gm, "");
                console.log("response....*****************************", result);
                console.log("Execution time(genai):", performance.now() - startTime, "milliseconds");
                TextForMentalHealthAPI = result;
                findAndSelectMatchingOption(TextForMentalHealthAPI);
                setReqSent(true);
                const newUserMessage = { text: (optiontext !== undefined && optiontext !== null && optiontext !== 'none' && optiontext !== '') ? optiontext : text, sender: "user", timestamp: formatTime() };
                setMessages((pre) => [...pre, newUserMessage]);
            } else {
                //if the user select the option then directly send the text by updating TextForMentalHealthAPI
                TextForMentalHealthAPI = text;
                setReqSent(true);
                const newUserMessage = { text: (optiontext !== undefined && optiontext !== null && optiontext !== 'none' && optiontext !== '') ? optiontext : text, sender: "user", timestamp: formatTime() };
                setMessages((pre) => [...pre, newUserMessage]);
            }

        } else {
            setReqSent(true);
            const newUserMessage = { text: (optiontext !== undefined && optiontext !== null && optiontext !== 'none' && optiontext !== '') ? optiontext : text, sender: "user", timestamp: formatTime() };
            setMessages((pre) => [...pre, newUserMessage]);
        }

        try {
            console.log("message length===============", messages.length)
            // if the length of message is greater zero then  send the api request for question
            if (messages.length > 1 && !IsconversationEnd) {

                var baseURL = `https://sync.runtime.cognitivegarage.ai/v3`;
                const data = {
                    channel: "web",
                    deployment_id: ChoreographID,
                    uuid: uuid,
                    type: "NBQ",
                    value: TextForMentalHealthAPI
                };
                const config = {
                    headers: {
                        'Authorization': `Bearer ${ACCESS_TOKEN_FOR_MENTAL_HEALTH_API}`,
                        'Content-Type': 'application/json',
                        'x-request-id': `${uuid}-${ChoreographID}`
                    },
                };
                const startTime = performance.now();
                const response = await axios.post(baseURL, data, config);
                console.log("response:===", response.data.data);
                console.log("Execution time:", performance.now() - startTime, "milliseconds");
                makeSpeech(response.data);

            } else {
                // else start the convertion by calling INIT_SESSION API 
                setIsconversationEnd(false);
                StarttheConversation("6614e7f13e29ac0fe3486441");
            }

        } catch (err) {
            console.log(err);
        }
        // //makeSpeech("cant login into my teams account");
        // try {
        //     const startTime = performance.now();
        //     const prompt = "answer should be in 20 words";
        //     const result = await model.generateContent(text + '\n' + prompt);
        //     const response = await result.response;
        //     console.log("response....", response.text());
        //     console.log("Execution time(genai):", performance.now() - startTime, "milliseconds");
        //     makeSpeech(response.text());
        // } catch (err) {
        //     console.log(err);
        // }
    };

    function removeQuotes(input) {
        if (input.includes('"') || input.includes("'")) {
            return input.replace(/["']/g, '');
        }
        return input;
    }

    async function makeSpeech(textdata) {
        console.log("sendMessage====================", textdata.data);
        let modifiedQueText  = "";
        try {
            const startTime = performance.now();
            const formData = new FormData();
            console.log("==========textdata", textdata)
            let conversationEndText = '';

            if (textdata.type === "question") {
                saveHistory(textdata.data.text,"GenAI");
                console.log("all messages - >>>>>>>>", messagesref.current);
                const historyString = await messagesref.current.map(item => JSON.stringify(item)).join(',');

                const prompt = `
                #Audience : Your audience is those who want to check his/her depression level by providing some answer to giving questions. An algorithm is already definded for this do not pre-assume or add about user's feelings and anything from your side.

                #chatHistory : This is the chat till now you have with user ----> "${historyString}"--- if sender is genAI then paricular message is send by you,else if sender is user then particular message is by user.

                #newQuestion : This is the new question you have to ask from user ----> "${textdata.data.text}".

                #FormatOfResponse : Check the latest message of user in chatHistory, first acknowledge the last response of user for example if user say hi/hello then start with hi/hello, if user last message is response of your previous question then include one line for that and then after acknowlegement ask the Question wisely, if require include word like - please,can you, for better support, may i etc.

                #Objective: using the given format ask questions accurately from users based on the given "newQuestion" and engage them with empathy and support.

                #OtherRules : Don't assume situation of user that how he feeling currently. Don't include sentences like "i understand you feeling depressed/bad" as these text preassuming the user situation. 
                Do not change the original question asked entirely i.e newQuestion.
                Only show the user that you are listening to them and you are here to help them and response should not feel like repetitive. Generate the text only and do not add anything to the the response text.
                
                #IMPORTANT : make sure that you are not repeating sentenses and starting with same words that is used in previous response (check history for that). for example do not keep saying thank you in every sentences.

                #responseFormat : Your response format is a single string of only response without any special marks and use simple words. always check last response in chatHistory response pattern should not be similar. be creative always generate a new response.
                `;

                console.log("prompt==========,", prompt)
                try {
                    const completion = await openai.chat.completions.create({
                        messages: [{"role": "system", "content": "You are a general supportive chatbot that talk to users like humans and can ask them questions formally."},
                                   {"role": "user", "content": prompt},
                                ],
                        model: "gpt-4o",
                    });
                    //const result = await model.generateContent(prompt);
                    console.log("result==>", completion.choices[0].message.content);
                    modifiedQueText = removeQuotes(completion.choices[0].message.content);
                } catch (error) {
                    console.error(error);
                    modifiedQueText = textdata.data.text;
                }

                



                formData.append('text', modifiedQueText);
            } else {
                if ('DecisionMH_Yes' in textdata.data.decision_points) {
                    console.log("<<<<<<<<<<<<Dicision percentage >>>>>>>>>>>>>>>>", parseFloat(textdata.data.decision_points.DecisionMH_Yes))
                    if (parseFloat(textdata.data.decision_points.DecisionMH_Yes) >= 50) {
                        setChoreographID("661e25b7a365a79fba0b20dc");
                        console.log("ChoreographID==================", ChoreographID);
                        StarttheConversation("661e25b7a365a79fba0b20dc");
                        return
                    } else {
                        conversationEndText = `Thank you for sharing your details with me.Based on the information you've provided, I'm pleased to tell you that you are not showing signs of severe depression, with a likelihood of ${parseFloat(textdata.data.decision_points.DecisionMH_Yes)}%.It's important to take care of your mental health, and I'm here to assist you. If you feel it would be helpful, please consider scheduling an appointment to discuss ways to support your well-being!`
                        setIsconversationEnd(true)
                        formData.append('text', conversationEndText);
                    }

                } else {
                    conversationEndText = `Thank you for trusting and sharing your personal information with me.As per my initial analysis, you are suffering from Severe Depression with chances ${parseFloat(textdata.data.decision_points.FeelingDepressed_Yes)}%.It's a very critical time,  I advise you to make an appointment, so that I can assist you in the best possible way!!`
                    setIsconversationEnd(true)
                    formData.append('text', conversationEndText);
                }


            }

            if (displayOption === 'ChatOnly') {
                const newBotMessage = { text: textdata.type === "question" ? modifiedQueText : conversationEndText, sender: "GenAI", options: textdata.type === "question" ? textdata.data.options : [] };
                setcurrentOptions(textdata.type === "question" ? textdata.data.options : [])
                setMessages((pre) => [...pre, newBotMessage]);
                setReqSent(false);
            } else {


                const response = await axios.post(process.env.REACT_APP_AVATAR_BACKEND_URI + `/synthesize?blend=${displayOption === "ChatAndAudio" ? false : true}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                let { blend_data, synthesized_audio } = response.data;
                console.log("Execution time(blandshapes):", performance.now() - startTime, "milliseconds");

                const newClips = [];
                //Below blend_data wll be null only in chat only option case
                if (blend_data !== null) {
                    for (let i = 0; i < Object.keys(morphTargets).length; i++) {
                        newClips.push(createAnimation(blend_data, morphTargets[i], morphTargetsName[i]));
                    }
                }
                synthesized_audio = process.env.REACT_APP_AVATAR_BACKEND_URI + '/' + synthesized_audio;

                setAudioSource(synthesized_audio);
                setRef();
                setClips(newClips);
                const newBotMessage = { text: textdata.type === "question" ? modifiedQueText: conversationEndText, sender: "GenAI", options: textdata.type === "question" ? textdata.data.options : [] };
                setcurrentOptions(textdata.type === "question" ? textdata.data.options : [])
                setMessages((pre) => [...pre, newBotMessage]);
                setReqSent(false);
            }

        } catch (err) {
            console.error(err);
            speak(modifiedQueText==="" ? textdata.data.text : modifiedQueText) //
            const newBotMessage = { text: textdata.type === "question" ? modifiedQueText : "Thank you for contacting.", sender: "GenAI", options: textdata.type === "question" ? textdata.data.options : [] };
            setcurrentOptions(textdata.type === "question" ? textdata.data.options : [])
            setMessages((pre) => [...pre, newBotMessage]);
            setReqSent(false);
        }
    }

    //only call the fucntion when blend failed to response 
    const speak = (text) => {
        console.log("speak funtion called");
        if (speechSynthesis.speaking) {
            console.error('speechSynthesis.speaking');
            return;
        }
        if (text !== '') {
            setPlaying(true)
            // Split the text into smaller chunks
            const chunkLength = 120; // Define the maximum chunk length
            const textChunks = text.match(new RegExp('.{1,' + chunkLength + '}[.!?]|.{1,' + chunkLength + '}', 'g'));

            // Get list of all available voices
            var voices = speechSynthesis.getVoices();

            // Filter for voices that are English-Indian and female (based on name as proxy)
            let indianFemaleVoices;

            const userAgent = navigator.userAgent;
            const isChrome = userAgent.includes('Chrome') && userAgent.includes('Safari') && !userAgent.includes('Edg');
            const isEdge = userAgent.includes('Edg');

            if (isChrome) {
                console.log('This is Chrome');
                indianFemaleVoices = voices.filter(voice => voice.lang === 'hi-IN');
            } else if (isEdge) {
                console.log('This is Edge');
                indianFemaleVoices = voices.filter(voice => voice.lang === 'hi-IN' && voice.name === 'Microsoft Swara Online (Natural) - Hindi (India)');
            } else {
                console.log('This is not Chrome or Edge; it will take the default voice.');
            }

            // Function to enqueue speaking of each chunk
            const enqueueChunk = (index) => {
                if (index < textChunks.length) {
                    var utterThis = new SpeechSynthesisUtterance(textChunks[index]);

                    // Use the first Indian female voice if available
                    if (indianFemaleVoices.length > 0) {
                        utterThis.voice = indianFemaleVoices[0];
                    } else {
                        console.log('No Indian female voice available. Using default voice.');
                    }

                    utterThis.onend = () => {
                        enqueueChunk(index + 1);
                    };
                    console.log(utterThis);
                    speechSynthesis.speak(utterThis);

                }
                else{
                    setPlaying(false);
                }
            };

            // Start speaking from the first chunk
            enqueueChunk(0);
        }
    };

    const toggleListen = () => {

        if (reqSent) {
            return;
        }
        // If currently speaking, stop the speech synthesis
        if (speechSynthesis.speaking) {
            speechSynthesis.cancel();
        }

        if (isListening) {
            recognition.abort();
            recognitionAborted.current = true;
            setIsListening(false);
        } else {
            recognitionfunc();
            setIsListening(true);
        }
    };

    const recognitionfunc = () => {
        recognitionAborted.current = false;
        recognition.start();
        recognition.onend = () => {
            if (!recognitionAborted.current) {
                recognition.stop();
                setIsListening(false);
            }
        };
    }

    recognition.onresult = (event) => {
        stopEverything()
        const speechToText = event.results[0][0].transcript;
        sendMessage(speechToText, true, "", false); // Automatically submit the converted text
    };


    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);


    const handleFormSubmit = (e) => {
        e.preventDefault();
        stopEverything()
        if (textInput === "") return;
        sendMessage(textInput, false);
        setTextInput("");
    }

    // const TextInputComponent = () => {
    //     const [textInput, setTextInput] = useState("");
    //     const handleFormSubmit = (e) => {
    //         e.preventDefault();
    //         if (textInput == "") return;
    //         sendMessage(textInput, false, "", false);
    //         setTextInput("");
    //     }

    //     return (
    //         <form onSubmit={handleFormSubmit} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
    //             <input
    //                 className="form-control me-2"
    //                 type="text"
    //                 value={textInput}
    //                 onChange={(e) => setTextInput(e.target.value)}
    //                 disabled={reqSent}
    //                 placeholder={isListening ? "Listening..." : "Type your message..."}
    //                 style={{ border: 'none', boxShadow: '0px 0px 5px rgba(0,0,0,0.2)', borderRadius: '20px', padding: '10px', marginRight: '10px', marginLeft: '10px' }}
    //             />
    //             <button type="submit" className="btn" disabled={reqSent} style={{ border: 'none', borderRadius: '20px', background: 'none', cursor: 'pointer', paddingLeft: window.innerWidth <= 425 ? '0' : '12px' }}>
    //                 <i className="bi bi-send" style={{ fontSize: '1.5em' }}></i>
    //             </button>

    //         </form>
    //     )
    // }

    const HandleChatTextBoxtoggle = () => {
        if (!reqSent) {
            setChatTextBoxtoggle(!ChatTextBoxtoggle);
        }
    }



    // End of play
    function playerEnded(e) {
        setAudioSource(null);
        setPlaying(false);
        setReqSent(false);
        if (micUsed.current) {
            setIsListening(true);
            recognitionfunc();
        }
    }

    // Player is read
    const playerReady = (e) => {
        console.log('file read');
        setPlaying(true);
        const audio = audioPlayer.current.audioEl.current;
        audio.muted = false;
        audio.play().catch(err => console.error("Audio play failed:", err));
    };

    const handleChatTypechange = (e) => {
        setDisplayOption(e.target.value)
        if (e.target.value === 'AvatarOnly') {
            setChatTextBoxtoggle(false);
        } else if (e.target.value === 'ChatAndAvatar' || e.target.value === 'ChatOnly') {
            setChatTextBoxtoggle(true);
        }
    }



    const handleOptionChange = (messageId, optionId, optiontext, fromVoiceAndText = false) => {
        setSelectedOption({
            ...selectedOption,
            [messageId]: optionId
        });

        // Update selectedOptions to disable further selections for this message
        setSelectedOptions(prev => ({
            ...prev,
            [messageId]: {
                selectedId: optionId,
                disabled: true  // Disable further selections for this message
            }
        }));

        if (!fromVoiceAndText) {
            sendMessage(optionId, false, optiontext, true);
        }

    };

    const toggleOptionsVisibility = () => {
        setShowOptions(!showOptions);
    };

    const stopEverything = () => {
        if (playing) {
            audioPlayer.current.audioEl.current.pause();
            setAudioSource(null);
            setPlaying(false);
            setReqSent(false);
            setClips([]);
        }
        if (isListening) {
            recognition.abort();
            setIsListening(false);
        }
        speechSynthesis.cancel(); // Stop any ongoing speech synthesis
    };




    return (
        <>
            <Canvas style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
                <Html>
                    <ReactAudioPlayer
                        src={audioSource}
                        ref={audioPlayer}
                        onEnded={playerEnded}
                        onCanPlayThrough={playerReady}
                        muted= {true}
                    />
                </Html>
            </Canvas>

            {ChatTextBoxtoggle &&
                <div className={`col-lg-${ChatTextBoxtoggle && (displayOption === 'ChatAndAudio' || displayOption === 'ChatOnly') ? '12' : '6'} col-md-6 col-sm-12`} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: '0' }}>
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{ display: "flex", justifyContent: "end", alignItems: "center", flexDirection: 'column' }}>
                        <div style={{
                            height: window.innerWidth <= 425 ? (displayOption === 'ChatAndAudio' || displayOption === 'ChatOnly') ? '75vh' : '40vh' : '65vh',
                            width: '100%',
                            overflowY: "scroll",
                            scrollbarColor: 'none',
                            scrollbarWidth: 'none',
                            marginBottom: "10px",
                        }}>
                            {messages.map((message, index) => (
                                <div
                                    key={index}
                                    style={{
                                        marginTop: '10px',
                                        padding: "5px",
                                        textAlign: message.sender === "GenAI" ? "left" : "right",
                                    }}
                                >
                                    <div style={{ textAlign: message.sender === "GenAI" ? "left" : "right", marginBottom: '5px', color: '#888', fontSize: '0.8em' }}>
                                        {message.timestamp}
                                    </div>
                                    <span
                                        style={{
                                            backgroundColor: message.sender === "GenAI" ? "#11d044" : "#E0E0E0",
                                            color: message.sender === "GenAI" ? "white" : "#000",
                                            padding: "8px",
                                            borderTopLeftRadius: message.sender === "GenAI" ? 0 : "15px",
                                            borderTopRightRadius: message.sender === "GenAI" ? "15px" : 0,
                                            borderBottomRightRadius: "15px",
                                            borderBottomLeftRadius: "15px",
                                            boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
                                            wordWrap: "break-word",
                                            maxWidth: "80%",
                                            display: "inline-block",
                                            overflowWrap: "break-word",
                                            textAlign: "left"
                                        }}
                                    >
                                        {<div dangerouslySetInnerHTML={{ __html: message.text }} />}
                                    </span>
                                    {message.options && message.options.length > 0 && (
                                        <div style={{ display: 'flex' }}>
                                            <div style={{
                                                display: 'inline-flex',
                                                // display: 'flex',
                                                flexDirection: 'column',
                                                marginTop: '10px',
                                                backgroundColor: "#11d044",
                                                padding: "8px",
                                                borderTopLeftRadius: message.sender === "GenAI" ? 0 : "15px",
                                                borderTopRightRadius: message.sender === "GenAI" ? "15px" : 0,
                                                borderBottomRightRadius: "15px",
                                                borderBottomLeftRadius: "15px",
                                                boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
                                                maxWidth: "80%",
                                                color: "white"
                                            }}>
                                                {message.options.map((option) => (
                                                    <label key={option.id} style={{ margin: '5px' }}>
                                                        <input
                                                            type="radio"
                                                            name={`options-${index}`}
                                                            value={option.id}
                                                            checked={selectedOptions[index]?.selectedId === option.id}
                                                            onChange={() => handleOptionChange(index, option.id, option.text)}
                                                            disabled={selectedOptions[index]?.disabled || false}
                                                            style={{ marginRight: '8px' }}
                                                        />
                                                        {option.text}
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>
                        {(ChatTextBoxtoggle && window.innerWidth <= 425) ?
                            <div className="col d-flex align-items-center justify-content-start" style={{ width: '100%', padding: '0' }}>
                                {displayOption === 'ChatOnly' ? null :
                                    <>
                                        {isListening ?
                                            <span style={{ cursor: 'pointer', color: "#32B749" }} onClick={toggleListen}>
                                                <i className="bi bi-mic-fill" style={{ fontSize: '1.8em' }}></i>
                                            </span>
                                            : <span style={{ cursor: 'pointer', color: "#32B749" }} onClick={toggleListen} >
                                                <i className="bi bi-mic-mute-fill" style={{ fontSize: '1.8em' }}></i>
                                            </span>
                                        }
                                        {playing && (
                                            <div
                                                data-toggle="tooltip"
                                                title="Stop Speaking"
                                                data-placement="top"
                                                onClick={stopEverything}
                                                style={{ position: 'relative', width: '3.8em', height: '3.8em', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                <Lottie animationData={speaking} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100%' }} />
                                                <i className="bi bi-stop-circle" style={{cursor: 'pointer',position: 'absolute'}}> </i>
                                                {/* <img src={stopSpeakingICON} alt="stop speaking" style={{ position: 'absolute', width: '30%', height: '30%', objectFit: 'contain' }} /> */}
                                            </div>
                                        )}

                                    </>
                                }

                                <form onSubmit={handleFormSubmit} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                    <input
                                        className="form-control me-2"
                                        type="text"
                                        value={textInput}
                                        onChange={(e) => setTextInput(e.target.value)}
                                        disabled={reqSent}
                                        placeholder={isListening ? "Listening..." : "Type your message..."}
                                        style={{ border: 'none', boxShadow: '0px 0px 5px rgba(0,0,0,0.2)', borderRadius: '20px', padding: '10px', marginRight: '10px', marginLeft: '10px' }}
                                    />
                                    <button
                                        type="submit"
                                        className="btn"
                                        disabled={reqSent}
                                        style={{
                                            border: 'none',
                                            borderRadius: '20px',
                                            background: 'none',
                                            cursor: 'pointer',
                                            paddingLeft: window.innerWidth <= 425 ? '0' : '12px'
                                        }}
                                    >
                                        <i className="bi bi-send" style={{ fontSize: '1.5em' }}></i>
                                    </button>

                                </form>
                                {/* {ChatTextBoxtoggle ?
                                    <span style={{ cursor: 'pointer' }} onClick={HandleChatTextBoxtoggle}>
                                        <i className="bi bi-x-circle" style={{ fontSize: '1.8em' }}></i>
                                    </span>
                                    : <span style={{ cursor: 'pointer' }} onClick={HandleChatTextBoxtoggle}>
                                        <i className="bi bi-chat-dots" style={{ fontSize: '1.8em' }}></i>
                                    </span>
                                }  */}
                                {showOptions ? <span style={{ marginRight: '2px' }}><select
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={displayOption}
                                    onChange={e => handleChatTypechange(e)}
                                >
                                    <option value="ChatAndAvatar">Chat and Avatar</option>
                                    <option value="AvatarOnly">Avatar only</option>
                                    <option value="ChatOnly">Chat only</option>
                                    <option value="ChatAndAudio">Chat and Audio</option>
                                </select>
                                </span> : null}
                                {showOptions ?
                                    <span style={{ cursor: 'pointer' }} onClick={toggleOptionsVisibility}>
                                        <i className="bi bi-x-circle" style={{ fontSize: '1.8em' }}></i>
                                    </span>
                                    : <span style={{ cursor: 'pointer' }} onClick={toggleOptionsVisibility}>
                                        <i className="bi bi-chat-dots" style={{ fontSize: '1.8em' }}></i>
                                    </span>
                                }
                            </div>
                            : 
                            <form onSubmit={handleFormSubmit} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <input
                                    className="form-control me-2"
                                    type="text"
                                    value={textInput}
                                    onChange={(e) => setTextInput(e.target.value)}
                                    disabled={reqSent}
                                    placeholder={isListening ? "Listening..." : "Type your message..."}
                                    style={{ border: 'none', boxShadow: '0px 0px 5px rgba(0,0,0,0.2)', borderRadius: '20px', padding: '10px', marginRight: '10px', marginLeft: '10px' }}
                                />
                                <button
                                    type="submit"
                                    className="btn"
                                    disabled={reqSent}
                                    style={{
                                        border: 'none',
                                        borderRadius: '20px',
                                        background: 'none',
                                        cursor: 'pointer',
                                        paddingLeft: window.innerWidth <= 425 ? '0' : '12px'
                                    }}
                                >
                                    <i className="bi bi-send" style={{ fontSize: '1.5em' }}></i>
                                </button>

                            </form>
                        }
                    </div>
                </div>
            }

            {(!ChatTextBoxtoggle || window.innerWidth > 425) && (
                <div style={{ width: "100vw", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
                    {displayOption === 'ChatOnly' ? null : (
                        <div className="col d-flex align-items-center justify-content-start" style={{ width: '100%' }}>
                            {isListening ?
                                <span style={{ cursor: 'pointer', color: "#32B749" }} onClick={toggleListen}>
                                    <i className="bi bi-mic-fill" style={{ fontSize: window.innerWidth <= 425 ? '2em' : '2.8em' }}></i>
                                </span>
                                : <span style={{ cursor: 'pointer', color: "#32B749" }} onClick={toggleListen} >
                                    <i className="bi bi-mic-mute-fill" style={{ fontSize: window.innerWidth <= 425 ? '2em' : '2.8em' }}></i>
                                </span>
                            }
                            <div className="sound-waves ml-2">
                                <div className="wave" style={{ width: '60px', height: '60px', display: 'flex', alignItems: 'centre', }}>
                                    {isListening && (<Lottie animationData={listening} />)}
                                    {reqSent && !playing && (<Lottie animationData={loading} />)}
                                    {/* {playing && (<Lottie animationData={speaking} />)} */}
                                    {playing && (
                                        <div
                                            data-toggle="tooltip"
                                            title="Stop Speaking"
                                            data-placement="top"
                                            onClick={stopEverything}
                                            style={{ position: 'relative', width: '100%', height: '100%', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <Lottie animationData={speaking} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100%' }} />
                                            {/* <img src={stopSpeakingICON} alt="stop speaking" style={{ position: 'absolute', width: '30%', height: '30%', objectFit: 'contain' }} />
                                             */}
                                              <i className="bi bi-stop-circle" style={{cursor: 'pointer',position: 'absolute'}}>
                                    </i>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>)}
                    <div className="col d-flex align-items-center justify-content-end">
                        {showOptions ? <span style={{ marginRight: "5px" }}><select
                            className="form-select"
                            aria-label="Default select example"
                            value={displayOption}
                            onChange={e => handleChatTypechange(e)}
                        >
                            <option value="ChatAndAvatar">Chat and Avatar</option>
                            <option value="AvatarOnly">Avatar only</option>
                            <option value="ChatOnly">Chat only</option>
                            <option value="ChatAndAudio">Chat and Audio</option>
                        </select>
                        </span> : null}

                        {showOptions ?
                            <span style={{ cursor: 'pointer' }} onClick={toggleOptionsVisibility}>
                                <i className="bi bi-x-circle" style={{ fontSize: window.innerWidth <= 425 ? '2em' : '2.5em' }}></i>
                            </span>
                            : <span style={{ cursor: 'pointer' }} onClick={toggleOptionsVisibility}>
                                <i className="bi bi-chat-dots" style={{ fontSize: window.innerWidth <= 425 ? '2em' : '2.5em' }}></i>
                            </span>
                        }
                    </div>
                </div>
            )}
        </>
    )
}

export default ChatWithMicComponent;