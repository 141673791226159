import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import useApi from '../lib/useApi';
import { toast } from 'react-toastify';
import BotAi from './BotAi';

function ChatingBot() {
    const [searchparams, setSearchparms] = useSearchParams();
    const [domain, setDomain] = useState("");
    const [token, setToken] = useState("");
    const [GenerateTokens, setGenerateTokens] = useState([]);
    const [matchtoken, setMatchToken] = useState(false);
    console.log("matchtoken:", matchtoken);

    const { fecthApi } = useApi();
    let userData = JSON.parse(localStorage.getItem("userdata"));


    useEffect(() => {
    fetchAllToken()    
        if (userData) {
          setMatchToken(true);
        }
      }, []);

    const fetchAllToken = async () => {
        try {
          const response = await fecthApi({
            api: `token/get`,
            method: "GET",
          });
          console.log("fetchTokens ~ response:", response);
    
          if (response?.status === true) {
            setGenerateTokens(response?.tokens);
            setDomain(
              response.tokens
                .filter((token) => token && token.domain)
                .map((token) => token.domain)
            );
    
            setToken(
              response.tokens
                .filter((token) => token && token.token)
                .map((token) => token.token)
            );
    
            if (!userData) {
              if (
                response?.tokens?.find(
                  (token) => token?.token === searchparams.get("token")
                )
              ) {
                setMatchToken(true);
              } else {
                toast.error("you can not access without token");
              }
            }
          } else {
            toast.error(response.error.message);
          }
        } catch (error) {
          toast.error(error.message);
        }
      };



  return (
    <>
 {/* { matchtoken && <BotAi/>} */}
 {<BotAi/>}
    </>
  )
}

export default ChatingBot