import React, { useEffect, useState } from 'react';
import ChatBot from './avatar';
import { useAuth } from '../auth/authContext';

const PhoneVerificationPage = () => {
    const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneCode, setPhoneCode] = useState('');
    const [otpValue, setOtpValue] = useState('');
    const [otpSentToUser, setOtpSentToUser] = useState('0000');
    const [sendingOtp, setSendingOtp] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const { currentUser } = useAuth();

    const removePlusSymbol = (code) => {
        if (code.startsWith('+')) {
            return code.substring(1);
        }
        return code;
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(`https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/helper_routers/get_user_by_email?email=${currentUser.email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'accept': 'application/json'
                    },
                });
                const data = await response.json();
                
                if(response.ok){
                    setPhoneNumberVerified(true);
                }
                setLoading(false); 
            } catch (error) {
                //console.log(error);
                setLoading(false); 
            }
        };

        fetchData();
    }, []);

    const sendUserInfo = async () =>{
        const phCode = removePlusSymbol(phoneCode);
        const words = currentUser.displayName.split(' ');
        let firstName = words[0];
        let lastName = words.length > 1 ? words.slice(1).join(' ') : 'lastName';
        try {
            const response = await fetch('https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/helper_routers/create_user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify({ 
                    CreatedBy: 1,
                    FirstName: firstName,
                    LastName: lastName,
                    Email: currentUser.email,
                    MobilePhone: phoneNumber,
                    EncryptedPassword: "Password",
                    PhoneCode: phCode,
                    Organization: "Dynapt"
                }),
            });

        } catch (error) {
            console.log(error);
        }
    };

    if (loading) {
        return <p>Loading...</p>; 
    }
   
       
    
    const sendOtp = () => {
        const phCode = removePlusSymbol(phoneCode);
        setSendingOtp(true);
        const random = Math.floor(Math.random() * 9000 + 1000);
        setOtpSentToUser(random);
        const reqToSend = {
            "receiver": `${phCode}${phoneNumber}@s.whatsapp.net`,
            "message": { "text": `Your otp is ${random}` }
        };

        fetch('https://wa.dynaptsolutions.com:8000/chats/send?' + new URLSearchParams({
            id: 'device_14',
        }), {
            method: 'POST',   
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reqToSend),
        }).then(() => {
            setOtpSent(true);
        }).catch((error) => {
            console.log(error);
        })     
    };

    const verifyOtp = () => {
        if (otpValue == otpSentToUser) {
            sendUserInfo();
            setOtpVerified(true);
            setPhoneNumberVerified(true);
        } else {
            setErrorMessage("Incorrect OTP");
        }
    };

    return (
        <div className="flex justify-center items-center h-screen">
            {phoneNumberVerified? (
                <ChatBot/>
            ):(
            <div className="max-w-md w-full p-8 bg-white shadow-lg rounded-lg">
                <label className="text-sm text-gray-600 font-bold">Verify your Phone Number</label>
                <div className="flex items-center mt-2">
                    <input
                        type="text"
                        autoComplete="off"
                        maxLength={4}
                        placeholder="+"
                        value={phoneCode}
                        onChange={(e) => {setPhoneCode(e.target.value);}}
                        className="w-1/5 px-2 py-2 text-gray-500 bg-transparent outline-none rounded-l-lg focus:border-indigo-600 shadow-sm transition duration-300 border border-r-0"
                    />
                    <input
                        type="tel"
                        autoComplete="tel"
                        required
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="w-4/5 px-3 py-2 text-gray-500 bg-transparent outline-none focus:border-indigo-600 shadow-sm rounded-r-lg transition duration-300 border border-l-0 border-l"
                    />
                    {!phoneNumberVerified && !otpSent && (
                        <button
                            type="button"
                            disabled={sendingOtp}
                            onClick={sendOtp}
                            className="ml-2 px-4 py-2 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition duration-300"
                        >
                            {sendingOtp ? 'Sending...' : 'Send OTP'}
                        </button>
                    )}
                </div>

                {otpSent && !otpVerified && (
                    <div className="mt-2 flex items-center">
                        <input
                            type="text"
                            value={otpValue}
                            onChange={(e) => { setOtpValue(e.target.value); setErrorMessage('') }}
                            className="w-full px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                        />
                        <button
                            type="button"
                            onClick={verifyOtp}
                            className="ml-2 px-4 py-2 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition duration-300"
                        >
                            Verify OTP
                        </button>
                    </div>
                )}
                {errorMessage && (
                    <p className="text-red-500 mt-2">{errorMessage}</p>
                )}
            </div>)}
        </div>
    );
};

export default PhoneVerificationPage;
